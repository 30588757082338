import Vue from "vue";
import VueRouter from "vue-router";
// import store from '@/store';
import { getToken } from "@/utils/cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/pc",
    component: () => import("../layout/pc"),
    children: [
      {
        path: "",
        name: "questionnaire",
        component: () => import("../views/questionnaire"),
      },
      {
        path: "contacts",
        name: "contacts",
        component: () => import("../views/contacts"),
      },
      {
        path: "temp",
        name: "temp",
        component: () => import("../views/temp"),
      },
      {
        path: "q-handle",
        name: "q-handle",
        component: () => import("../views/questionnaire/handle"),
      },
      {
        path: "/pc/share",
        redirect: "/pc/share/link",
        component: () => import("../views/questionnaire/share"),
        children: [
          {
            path: "/pc/share/link",
            name: "link",
            component: () => import("../views/questionnaire/share/link"),
          },
        ],
      },
      {
        path: "/pc/q-statustucs",
        redirect: "/pc/q-statustucs/survey",
        component: () => import("../views/questionnaire/statistics"),
        children: [
          {
            path: "/pc/q-statustucs/survey",
            name: "survey",
            component: () => import("../views/questionnaire/statistics/survey"),
          },
          {
            path: "/pc/q-statustucs/data",
            name: "data",
            component: () => import("../views/questionnaire/statistics/data"),
          },
          {
            path: "/pc/q-statustucs/icon",
            name: "icon",
            component: () => import("../views/questionnaire/statistics/icon"),
          },
          {
            path: "/pc/q-statustucs/analysis",
            name: "analysis",
            component: () =>
              import("../views/questionnaire/statistics/analysis"),
          },
          {
            path: "/pc/q-statustucs/report",
            name: "report",
            component: () => import("../views/questionnaire/statistics/report"),
          },
        ],
      },
    ],
  },
  {
    path: "/mobile/share",
    name: "share",
    component: () => import("../layout/mobile/share"),
  },
  {
    path: "/mobile/evaluatedPerson",
    name: "evaluatedPerson",
    component: () => import("../layout/mobile/evaluatedPerson"),
  },
  {
    path: "/mobile",
    redirect: "/mobile/home",
    component: () => import("../layout/mobile"),
    children: [
      {
        path: "/mobile/home",
        name: "home",
        component: () => import("../layout/mobile/home"),
      },
      {
        path: "/mobile/questionTitle/:uid",
        name: "questionTitle",
        component: () => import("../layout/mobile/questionTitle"),
      },
      {
        path: "/mobile/questionContent/:id/:replyId",
        name: "questionContent",
        component: () => import("../layout/mobile/questionContent"),
      },
      {
        path: "/mobile/end-page",
        name: "mobile-end-page",
        component: () => import("../layout/mobile/end-page"),
      },
      {
        path: "/mobile/statistics",
        name: "mobile-statistics",
        component: () => import("../layout/mobile/statistics"),
      },
    ],
  },
  {
    name: "401",
    path: "/401.html",
    component: () => import("../views/401"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  if(getToken() || to.path.indexOf("/401.html") >= 0){
    next();
  }else{
    if(!window.sessionStorage.fullPath){
      window.sessionStorage.fullPath = to.fullPath;
    }
  }
  
})


export default router;
