import Cookies from 'js-cookie';
const TOKEN_KEY = 'token';

export function getToken() {
  return Cookies.get(TOKEN_KEY);
}

export function setToken(token) {
  return Cookies.set(TOKEN_KEY, token);
}

export function removeToken() {
  return Cookies.remove(TOKEN_KEY);
}

export function getUsername() {
  return Cookies.get('username');
}

export function setUsername(name) {
  return Cookies.set('username', name);
}

export function getDevice() {
  return Cookies.get('device');
}

export function setDevice(device) {
  return Cookies.set('device', device);
}

export function removeUsername() {
  return Cookies.remove('username');
}
