import Vue from 'vue';
import Vuex from 'vuex';
const date = new Date();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    query: {
      date: `${date.getFullYear()}-${date.getMonth() + 1}`,
      type: 'net-sales'
    },
    viewType: 'table',
    auths: [],
    userInfo: {},
    updateTime: +new Date()
  },
  getters: {
    date({ query }) {
      return `${query.date.replace('-', '年')}月`;
    }
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload;
    },
    changeQuery(state, payload) {
      state.query = payload;
    },
    changeViewType(state, payload) {
      state.viewType = payload;
    },
    changeAuths(state, payload) {
      state.auths = payload;
    },
    changeUpdateTime(state, payload) {
      state.updateTime = payload;
    },
    changeLoading(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
  }
})
