import Vue from 'vue';
import {
  Upload,
  Dialog,
  Steps,
  Step,
  Row,
  Col,
  Button,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Progress,
  Loading,
  MessageBox,
  Message,
  Notification,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Switch,
  Tabs,
  TabPane,
  Select,
  Option,
  Input,
  Checkbox,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Tooltip,
  Tree,
  Form,
  FormItem,
  Tag,
  Autocomplete,
  CheckboxGroup,
  Drawer
} from 'element-ui';

Vue.use(Dialog);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Progress);
Vue.use(Upload);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popover);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Tooltip);
Vue.use(Tree);
Vue.use(Form);
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(Autocomplete)
Vue.use(CheckboxGroup)
Vue.use(Drawer)


Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;