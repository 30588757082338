import http from '@/utils/http';

export default {
  // 获取权限
  userAuth: data => http.post('/user/auth', data),
  // 获取权限
  userQueryUserInfo: data => http.post('/user/queryUserInfo', data),
  // 获取用户信息
  queryByUsername: data => http.post('/user/queryByUsername', data),

  // 问卷-新增/编辑
  questionAddOrUpdate: data => http.post('/questionnaire/addOrUpdate', data),
  // 问卷-查询分页
  questionQueryByQuestionnaireId: data => http.post('/questionnaire/queryByQuestionnaireId', data),
  // 问卷-查询单条
  questionFindById: data => http.post('/questionnaire/findById', data),
  // 问卷-重命名
  questionRename: data => http.post('/questionnaire/rename', data),
  // 问卷-投放
  questionSend: data => http.post('/questionnaire/send', data),
  //问卷-被评价人消息推送
  questionMessagePush: data => http.post('/questionnaireReply/sendMsg', data),
  // 问卷-启动/停止
  questionStopOrStart: data => http.post('/questionnaire/stopOrStart', data),
  // 问卷-删除到回收站/恢复
  questionDeleteByBin: data => http.post('/questionnaire/deleteByBin', data),
  // 问卷-回收站彻底删除（物理）
  questionDelete: data => http.post('/questionnaire/delete', data),
  // 题目-添加/编辑
  subjectAddOrUpdate: data => http.post('/subject/addOrUpdate', data),
  // 题目-查询
  subjectQueryByQuestionnaireId: data => http.post('/subject/queryByQuestionnaireId', data),
  // 题目-删除/恢复
  subjectDeleteByBin: data => http.post('/subject/deleteByBin', data),
  // 题目-回收站删除（物理）
  subjectDelete: data => http.post('/subject/delete', data),
  // 题目-回收站删除（物理）( 批量 )
  subjectDeleteByIds: data => http.post('/subject/deleteByIds', data),
  // 返回用户列表
  userQueryPage: data => http.post('/user/queryPage', data),
  // 返回部门列表树（部门添加用户users字段）
  userDirectory: data => http.post('/user/directory', data),
  // 设置是否可提交
  questionSetCanSubmit: data => http.post('/questionnaire/setCanSubmit', data),
  // 统计
  questionnaireStatistical: data => http.post('/questionnaire/statistical', data),
  // 获取评分人||被评分人列表
  subjectQueryUsers: data => http.post('/subject/queryUsers', data),
  // 分享
  questionnaireShare: data => http.post('/questionnaire/share', data),
  // 复制
  questionnaireCopy: data => http.post('/questionnaire/copy', data),
  // 评分人&被评分人模板导出
  downloadJobNo: data => http.post('/base/download/new/grander', data,{responseType: 'blob'}),
  // 评分人&被评分人导入
  importDataJobNo: data => http.post('/base/importData/new/grander', data),
  //问卷-被评分人
  questionnaireToRaterScore:data => http.post('/questionnaire/findToRaterScore',data),
  //问卷-导出被评分人-统计
  questionnaireDownExcel:data => http.post('/questionnaire/downExcel',data,{responseType: 'blob'}),
  //问卷-导出被评分人-明细
  questionnaireDownExcelDetail:data => http.post('/questionnaire/downExcelDetail',data,{responseType: 'blob'}),
  //设置排序
  subjectSort:data => http.post('/subject/saveSort',data),
  //导出评分人 应有评分人实际评分人 /questionnaire/downExcelRateUser
  downExcelRateUser:data => http.post('/questionnaire/downExcelRateUser',data,{responseType: 'blob'})

};
