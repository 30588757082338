import axios from 'axios';
import { Message } from 'element-ui';
// import store from '../store';
import { getToken } from '@/utils/cookie';
// const baseURL = 'http://qw.nvc-lighting.com.cn:8089'
// const baseURL = 'http://192.168.1.125:8082'
//const baseURL = 'http://qw.nvc-lighting.com.cn:8082'
const baseURL = process.env.NODE_ENV === 'development' ? '/api' : 'https://qw.nvc-lighting.com.cn:8082';
// const baseURL = 'http://192.168.200.120:8089'//process.env.NODE_ENV === 'development' ? '/api' : 'http://qw.nvc-lighting.com.cn:8082';
// const baseURL = 'http://localhost:8082'//process.env.NODE_ENV === 'development' ? '/api' : 'http://qw.nvc-lighting.com.cn:8082';
// const href = location.href;
// switch (true) {
//   case href.includes('生成'):
//     baseURL = '';
//     break;
//   case href.includes('测试'):
//     baseURL = '';
//     break;
//   default: // 开发
//     baseURL = '';
// }

// 创建axios实例
const service = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 20000
});

const errHandle = (code, message) => {
  switch (code) {
    case 401:
      // store.dispatch('logout').then(() => {
      //   location.reload(); // 为了重新实例化 vue-router 对象,避免bug
      // });
      break;
    case 403:
      // 没有权限
      Message.error(message);
      break;
    default:
      Message.error(message);
  }
};

// request 拦截器
service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.token = token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 兼容params和data
    const useParams = ['get', 'delete'].includes(config.method);
    config[useParams ? 'params' : 'data'] = config.data || config.params || {};
    if (config.method === 'get') {
      config.params.t = +new Date();
    }
    return config;
  },
  error => Promise.reject({
    type: 'clientReq',
    error
  })
);

// response 拦截器
service.interceptors.response.use(
  // response => response,
  response => {
    const { message, code } = response.data;

    if (code === 200) return response.data;
    if (Object.prototype.toString.call(response.data) === '[object Blob]') return response.data;
    errHandle(code, message);
    return Promise.reject({
      type: 'client',
      code,
      message,
      data: response.data.data
    });
  },

  error => {
    const { message, code } = error.response.data;
    errHandle(code, message);
    return Promise.reject({
      type: 'server',
      code,
      message
    });
  }
);

export default service;
export {
  baseURL
};
