import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/reset.scss";
import "./styles/common.scss";
import "./utils/flexible";
import "./ui";
import VueClipboard from "vue-clipboard2";
import { NavBar, Icon, Button, Form, Field, Picker, Popup, Rate } from "vant";
import "vant/lib/index.css";
import * as echarts from "echarts";
import * as moment from "moment";
import {initInputDOM,nextFocus }from './utils/common'
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.prototype.initInputDOM = initInputDOM
Vue.prototype.nextFocus = nextFocus


Vue.config.productionTip = false;
Vue.use(VueClipboard)
  .use(NavBar)
  .use(Icon)
  .use(Button)
  .use(Form)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Rate);
new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
}).$mount("#app");

