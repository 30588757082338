<template>
  <router-view></router-view>
</template>

<script>
import api from "@/apis";
import { setToken, setDevice } from "@/utils/cookie";
import DeviceDetector from "device-detector-js";
import { encryption } from "./utils/tools";

export default {
  name: "app",
  methods: {
    getQueryStringByName(name) {
      var result = location.search.match(
        new RegExp("[?&]" + name + "=([^&]+)", "i")
      );
      if (result == null || result.length < 1) {
        return "";
      }
      return result[1];
    },
    getAuth(userId) {
      api
        .userAuth({
          userId,
        })
        .then(({ data }) => {
          this.$store.commit("changeAuths", data);
        });
    },
    getUserInfo(code, uid) {
      // console.log("code:" + code);
      // console.log("uid:" + uid);
      const userId = window.sessionStorage.userId;
      return new Promise((resolve) => {
        if (userId) {
          this.getAuth(userId);
          api
            .queryByUsername({
              username: userId,
            })
            .then(({ data }) => {
              const userId = data.userid || "";
              const str = encryption(userId);
              window.sessionStorage.userId = str;
              setToken(str);
              this.$store.commit("changeUserInfo", data);
              resolve();
            });
        } else {
          api
            .userQueryUserInfo({
              code,
              uid,
            })
            .then(({ data }) => {
              let userId = data.userid;

              let userNull =
                userId === "" || userId === null || userId === undefined;
              if (userNull && process.env.NODE_ENV === "development") {
                userId = "10007372";
                userNull = false;
              }

              if (userNull) {
                this.$router.replace("/401.html");
              } else {
                const str = encryption(userId);
                window.sessionStorage.userId = str;
                setToken(str);
                this.$store.commit("changeUserInfo", data);

                const fullPath = window.sessionStorage.fullPath;
                if (fullPath) {
                  window.sessionStorage.removeItem("fullPath");
                  this.$router.replace(fullPath);
                }
                resolve();
              }
            });
        }
      });
    },
    redirectRoute() {
      const userAgentInfo = navigator.userAgent;
      const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];

      const deviceDetector = new DeviceDetector();
      let device = deviceDetector.parse(userAgentInfo);
      setDevice(
        device.device.brand + " " + device.os.name + " " + device.client.name
      );
      let path = "/pc";
      let isPc = true;

      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          isPc = false;
          break;
        }
      }
      if (!isPc) {
        path = "/mobile";
      }
      // this.$router.replace(path);
      setTimeout(() => {
        const routePath = this.$route.path;
        if (["/", ""].includes(routePath)) {
          if (!routePath.includes(path)) {
            this.$router.replace(path);
          }
        }
      }, 100);
    },
  },
  mounted() {
    const code = this.getQueryStringByName("code") || "test";
    const uid = this.getQueryStringByName("uid") || "";
    this.getUserInfo(code, uid).then(() => {
      this.redirectRoute();
    });
  },
};
</script>

<style lang="scss" scoped></style>
