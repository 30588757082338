const CryptoJS = require("crypto-js");
export const downloadExcel = (data, filename) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const isEdge = window.navigator.userAgent.includes('Edge');
  if ('download' in document.createElement('a') && !isEdge) { // 非IE下载
    const el = document.createElement('a');
    el.style.display = 'none';
    el.href = URL.createObjectURL(blob);
    el.download = `${filename}.xlsx`;
    document.body.appendChild(el);
    el.click();
    URL.revokeObjectURL(el.href); // 释放URL 对象
    document.body.removeChild(el);
  } else { // IE10+下载
    navigator.msSaveBlob(blob);
  }
};

export const toChinesNum = num => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  let getWan = (temp) => {
    let strArr = temp.toString().split('').reverse()
    let newNum = ''
    let newArr = []
    strArr.forEach((item, index) => {
      newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
    })
    let numArr = []
    newArr.forEach((m, n) => {
      if (m !== '零') numArr.push(n)
    })
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m
          }
        } else {
          newNum += m
        }
      })
    } else {
      newNum = newArr[0]
    }

    return newNum
  }
  let overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

export const encryption = (word) => {
  if (typeof word === "object") {
    // 对象格式的转成json字符串
    word = JSON.stringify(word);
  }
  const key = CryptoJS.enc.Utf8.parse("0CoJUm6Qyw8W8jud"); // 十六位
  const iv = CryptoJS.enc.Utf8.parse("0102030405060708"); // 十六位
  const encrypted = CryptoJS.AES.encrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString();
};